import captalWorldLogo from "../../assets/homePageAssets/capitalWorld.svg";
import connectingDots from "../../assets/homePageAssets/connectingDotsFoundation.svg";
import filmiLogo from "../../assets/homePageAssets/finamee.svg";
import goldBricksLogo from "../../assets/homePageAssets/goldBricks.svg";
import tnaLogo from "../../assets/homePageAssets/tnaMortgages.svg";
import webTeqnik from "../../assets/homePageAssets/webTeqnik.svg";
import woodHavenLogo from "../../assets/homePageAssets/woodhaven.svg";

export const partnersData = [
  {
    img: captalWorldLogo,
    redirectionLink: "",
  },
  { img: connectingDots, redirectionLink: "" },
  { img: filmiLogo, redirectionLink: "" },
  { img: tnaLogo, redirectionLink: "" },
  { img: webTeqnik, redirectionLink: "" },
  { img: goldBricksLogo, redirectionLink: "" },
  { img: woodHavenLogo, redirectionLink: "" },
];
