import React from "react";
import { AppGeneralLayout } from "../../../components";
import AboutVisionStream from "./AboutVisionStream";
import BeOurPartner from "./BeOurPartner";
import ContactUs from "./ContactUs";
import HeroSection from "./HeroSection";
import "./homePage.css";
import OurPartner from "./OurPartner";
import VSScope from "./VSScope";
import VSServices from "./VSServices";

const HomePage = () => {
  return (
    <div className="">
      <AppGeneralLayout>
        <HeroSection />
        <AboutVisionStream />
        <BeOurPartner />
        <VSServices />
        <VSScope />
        <OurPartner />
        {/* <NewsAndInsights /> */}
        {/* <Testimonial /> */}
        <ContactUs />
      </AppGeneralLayout>
    </div>
  );
};

export default HomePage;
