import { Typography } from "@material-ui/core";
import React from "react";
import Slider from "react-slick";
import { partnersData } from "../../../data/constants/partnersData";

const OurPartner = () => {
  let settings = {
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-black" id="our_partner_home_page">
      <div className="container mx-auto px-4 md:px-0">
        <div className="md:max-w-[40%]">
          <Typography
            className="text-white"
            variant="h3"
            fontFamily={"raleway"}
            gutterBottom
          >
            Our Partners
          </Typography>

          {/* <Typography
            className="text-white"
            variant="body2"
            letterSpacing={"1.2px"}
            sx={{ maxWidth: "50%" }}
            fontFamily={"raleway"}
            style={{ paddingBottom: "40px" }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </Typography> */}
        </div>

        <Slider className="py-8 our_partner_slide" {...settings}>
          {partnersData.map((item, index) => (
            <img
              className="cursor-pointer object-cover p-8"
              // onClick={() => window.open(item.redirectionLink, "_blank")}
              key={index}
              src={item.img}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurPartner;
